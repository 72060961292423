<!-- JetBlue/CP Accounts -->
@if(profile.authProfileSource === 'leancreditprofile' &&
appConfigService.isCpEnabled){
<div class="bg-white charcoal">
  <!-- Top Section -->
  <div [ngStyle]="{ 'background-color': bgColor }" class="pa4">
    <div class="flex items-center justify-center pb3">
      <div
        [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
        data-qaid="firstName"
        class="fw8 f5 line-height-4"
      >
        <span>
          {{ loggedInSection?.topSection?.children?.greeting }}
        </span>
        {{ firstName }}
      </div>
    </div>

    <!-- TravelBank -->
    <div class="flex items-center justify-center flex-column">
      <p class="travel-bank-paragraph f7 mb0 fw4 line-height-3">
        {{ loggedInSection?.topSection?.children?.travelBank }}
      </p>
      <p class="travel-bank-paragraph fw8 f5 mb0">
        {{
          travelBalance
            | currency: 'USD' : 'symbol' : getCurrencyFormat(travelBalance)
        }}
      </p>
    </div>
  </div>
  <div class="mh4 mt3">
    <jb-body-text-container
      [content]="loggedInSection?.middleSection?.section?.bodyText"
      (click)="closeEvent.emit('close-modal')"
    >
    </jb-body-text-container>
  </div>
  <hr class="mv3 bl-0 br-0 bt-0 bb b--medium-gray" />
  <div class="mh4 mt3">
    <a
      href="javascript:void(0)"
      (click)="handleSignOut()"
      data-qaid="signOut"
      class="royal-blue no-underline underline-hover klarheit"
      >{{ loggedInSection?.signoutSection?.children?.title }}</a
    >
  </div>
  <!-- Bottom Section -->
  <div class="ws-normal pt4">
    <dot-tb-bottom-section
      [content]="loggedInSection?.bottomSection?.section"
    ></dot-tb-bottom-section>
  </div>
</div>
} @else {
<!-- TrueBlue/Mosaic Accounts -->
<div class="bg-white charcoal">
  <!-- Top Section -->
  <div [ngStyle]="{ 'background-color': bgColor }" class="pa4">
    <div class="flex flex-column justify-around items-center pb3">
      <div class="pb3">
        <!-- Mosaic Users Logo -->
        @if(isMosaic){
        <div class="flex items-center">
          <img
            [src]="loggedInSection?.topSection?.children?.image?.[0]?.srcMosaic"
            alt="M O S A I C"
            width="24"
            height="21"
            class="pl2"
          />
          <!-- class="ml2 klarheit fw6" -->
          <span
            class="f7 mb0 fw4 line-height-3"
            *ngIf="isMosaic"
            [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
            >{{ mosaicTier }}</span
          >
        </div>
        }@else{
        <!-- TrueBlue Users Logo-->
        <img
          [src]="loggedInSection?.topSection?.children?.image?.[0]?.srcDefault"
          alt="T R U E B L U E"
          width="75"
          height="8"
        />
        }
      </div>

      <!-- User first name-->
      <div
        [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
        data-qaid="firstName"
        class="fw8 f5 line-height-4 pb2"
      >
        <span> {{ loggedInSection?.topSection?.children?.greeting }} </span>
        {{ firstName }}
      </div>

      <!-- Trueblue number -->
      <div
        [ngClass]="theme === 'dark' ? 'white' : 'core-blue'"
        class="f7 mb0 fw4 line-height-3"
      >
        {{ loggedInSection?.topSection?.children?.TrueBlueNrtxt
        }}<span data-qaid="trueBlueNumber">{{ tbNumber }}</span>
      </div>
    </div>

    <!-- Tiles/Label/TravelBank -->
    <div class="flex justify-between items-center flex-row pb4">
      <div class="w-100">
        <div
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
          class="flex justify-center f7 mb0 fw4 line-height-3"
        >
          {{ loggedInSection?.topSection?.children?.myTiles }}
        </div>
        <div
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
          class="flex justify-center fw8 f5 mb0"
        >
          <span>{{ profile.numberOfTiles }}</span>
        </div>
      </div>
      @if(!!points){
      <div class="ph3 h-2-5">
        <hr class="bl-0 b--medium-gray bt-0 bb-0 vertical-line h-2-5" />
      </div>
      <div class="w-100">
        <div
          class="flex justify-center f7 mb0 fw4 line-height-3"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ loggedInSection?.topSection?.children?.points }}
        </div>
        <div
          class="flex justify-center fw8 f5 mb0"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ points }}
        </div>
      </div>
      } @if(!!travelBalance){
      <div class="ph3 h-2-5">
        <hr class="bl-0 b--medium-gray bt-0 bb-0 vertical-line h-2-5" />
      </div>
      <div class="w-100">
        <div
          class="flex justify-center f7 mb0 fw4 line-height-3"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{ loggedInSection?.topSection?.children?.travelBank }}
        </div>
        <div
          class="flex justify-center fw8 f5 mb0"
          [ngClass]="theme === 'dark' ? 'white' : 'charcoal'"
        >
          {{
            travelBalance
              | currency: 'USD' : 'symbol' : getCurrencyFormat(travelBalance)
          }}
        </div>
      </div>
      }
    </div>

    <!-- My Dashboard Btn  -->
    <div>
      <a
        jbButton
        [theme]="buttonType"
        class="royal-blue"
        [isFull]="true"
        [size]="'small'"
        [jbSitemapLinker]="loggedInSection?.topSection?.children?.link[0].href"
        (click)="closeEvent.emit('close-modal')"
      >
        {{ loggedInSection?.topSection?.children?.link[0].title }}
      </a>
    </div>
  </div>
  <div class="mh4 mt3">
    <jb-body-text-container
      [content]="loggedInSection?.middleSection?.section?.bodyText"
      (click)="closeEvent.emit('close-modal')"
    >
    </jb-body-text-container>
  </div>
  <hr class="mv3 bl-0 br-0 bt-0 bb b--medium-gray" />
  <div>
    <a
      href="javascript:void(0)"
      (click)="handleSignOut()"
      data-qaid="signOut"
      class="royal-blue no-underline underline-hover klarheit mh4 mt3"
      >{{ loggedInSection?.signoutSection?.children?.title }}</a
    >
  </div>
  <!-- Bottom Section -->
  <div class="ws-normal pt4">
    <dot-tb-bottom-section
      [content]="loggedInSection?.bottomSection?.section"
    ></dot-tb-bottom-section>
  </div>
</div>
}
