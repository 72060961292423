import {
  Inject,
  Injectable,
  OnDestroy,
  RendererFactory2,
  ViewEncapsulation,
} from '@angular/core';
import { LanguageService } from '@core/language/language.service';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile } from '@store/auth/types';
import { interval, Subscription } from 'rxjs';
import { filter, switchMap, take, takeWhile, tap } from 'rxjs/operators';

import { EventsService } from '../events/events.service';
import { DOCUMENT, WINDOW } from '../injection-tokens';
import { CookieStorageService } from '../storage';
import { mapDataLayerVariables } from './analytics.logic';
import { analyticsCustomEventName } from './analytics-custom-events.constants';
import { analyticsData } from './analytics-data-constants';
import {
  AnalyticsDataLayerValues,
  AnalyticsValues,
  OmniPageName,
  PageName,
} from './types';

@Injectable({ providedIn: 'root' })
export class AnalyticsService implements OnDestroy {
  currentPageName: OmniPageName;
  previousPageName: OmniPageName;
  tbNumber: string;
  tbPoints: string;
  mosaicStatus: string;
  cardStatus: string;
  userId: string;

  private consentCheckMaxAttempts = 20;

  private subscriptions: Subscription = new Subscription();
  private reversePageNameMap;

  constructor(
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window,
    private cookieStorage: CookieStorageService,
    private authFacade: AuthFacade,
    private rendererFactory: RendererFactory2,
    private eventsService: EventsService,
    private languageService: LanguageService,
    private cookieStorageService: CookieStorageService,
  ) {
    this.subscriptions.add(
      this.authFacade.authProfile
        .pipe(filter(Boolean))
        .subscribe((authProfile: AuthProfile) => {
          this.tbNumber = authProfile.membershipid;
          this.tbPoints = authProfile.points;
          this.mosaicStatus =
            authProfile.isMosaic === 'true' ? 'Mosaic' : 'General TrueBlue';
          this.cardStatus =
            !!authProfile.cardStatus && authProfile.cardStatus.length
              ? authProfile.cardStatus.join(', ')
              : 'Not a Card Holder';
        }),
    );

    this.reversePageNameMap = Object.keys(PageName).reduce((obj, item) => {
      obj[PageName[item]] = item;
      return obj;
    }, {});
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  trackEvent(name: string, values?: AnalyticsValues) {
    const processedAnalyticsValues = this.setDefaultValues(values);
    if (processedAnalyticsValues) {
      this.createAnalyticsDataLayer(processedAnalyticsValues);
    }
    this.createAnalyticsCustomEvent(name);
  }

  trackPageView(values?: AnalyticsValues) {
    this.resetSuite();
    const processedAnalyticsValues = this.setDefaultValues(values);
    if (processedAnalyticsValues) {
      this.createAnalyticsDataLayer(processedAnalyticsValues);
    }
    this.dispatchAnalyticsEvent();
  }

  createAnalyticsCustomEvent(name: string): void {
    switch (name) {
      case 'Page Validation Error':
      case 'TB Login Validation Page Errors':
      case 'Forgot Password Validation Page Errors':
        this.eventsService.dispatchCustomEvent(
          analyticsCustomEventName.validationError,
        );
        break;
      case 'TB Login Server Errors':
      case 'Forgot Password Server Errors':
        this.eventsService.dispatchCustomEvent(
          analyticsCustomEventName.serviceError,
        );
        break;
      default:
        this.eventsService.dispatchCustomEvent(name);
    }
  }

  createAnalyticsDataLayer(values?: AnalyticsValues): void {
    let mappedDataLayerValues: AnalyticsDataLayerValues = {};
    const brightTagPageData: AnalyticsDataLayerValues =
      this.window['brightTagPageData'];
    mappedDataLayerValues = mapDataLayerVariables(values);
    if (brightTagPageData !== undefined) {
      this.window['brightTagPageData'] = {
        ...brightTagPageData,
        ...mappedDataLayerValues,
      };
    } else {
      this.window['brightTagPageData'] = {
        ...analyticsData,
        ...mappedDataLayerValues,
      };
    }
    this.cookieStorage.setAnalyticsPrevPageCookie(values.pageName);
  }

  resetSuite(): void {
    const brightTagPageData = this.window['brightTagPageData'];
    if (brightTagPageData) {
      brightTagPageData.ValidationError = '';
      brightTagPageData.ServiceError = '';
      brightTagPageData.Events = '';
      brightTagPageData.Evar70 = '';
    }
  }

  /* mapping page name*/
  mapTitleToOmniture(pagename: PageName): OmniPageName {
    const x = OmniPageName[this.reversePageNameMap[pagename] as string];
    return x === undefined ? pagename : x;
  }

  /* setting page title*/
  setTitle(title: PageName): void {
    const changeName = title.split(' | ');
    let changedName = '';
    if (!!changeName && !changeName[0].includes('JetBlue')) {
      changedName = `${changeName[1]} | ${changeName[0]}`;
    } else {
      changedName = title;
    }

    this.previousPageName = this.currentPageName;
    this.currentPageName = this.mapTitleToOmniture(changedName as PageName);
  }

  waitForConsentThenLoadScripts() {
    let currentAttempts = 0;
    const cookieKey = 'notice_gdpr_prefs';

    interval(1000)
      .pipe(
        takeWhile(() => currentAttempts < this.consentCheckMaxAttempts),
        tap(() => currentAttempts++),
        switchMap(() => {
          const consentCookieValue =
            this.cookieStorageService.getCookie(cookieKey);
          return consentCookieValue ? [consentCookieValue] : [];
        }),
        filter(consentCookieValue => !!consentCookieValue),
        take(1),
      )
      .subscribe({
        next: () => {
          this.appendAnalyticsScripts();
        },
        complete: () => {
          if (currentAttempts >= this.consentCheckMaxAttempts) {
            console.warn('Max attempts reached. Loading GTM Script.');

            this.appendAnalyticsScripts();
          }
        },
      });
  }

  appendAnalyticsScripts() {
    const analyticsScriptSrcs = ['/ui-assets/analytics/google-tag-manager.js'];

    analyticsScriptSrcs.forEach(scriptSrc => this.appendScript(scriptSrc));
  }

  appendScript(scriptSrc, cbKey?) {
    if (cbKey) {
      return new Promise((resolve, reject) => {
        this.addScriptSrcToDom(scriptSrc);
        this.window[cbKey] = () => resolve('App measurement finished Loading.');
      });
    } else {
      this.addScriptSrcToDom(scriptSrc);
    }
  }

  dispatchAnalyticsEvent() {
    this.eventsService.dispatchCustomEvent(
      analyticsCustomEventName.jbAnalyticsEvent,
    );
  }

  processPreferenceCntrPageName(): string {
    if (!this.currentPageName) {
      return 'Jetblue | Preference Center';
    }
    const prefCntr = this.currentPageName.split(' | ');
    prefCntr.splice(1, 0, 'Preference Center');
    return `${prefCntr.join(' | ')}`;
  }

  private addScriptSrcToDom(scriptSrc) {
    const renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
    const script = renderer.createElement('script');
    renderer.setAttribute(script, 'type', 'text/javascript');
    script['src'] = scriptSrc;
    renderer.appendChild(this.document.body, script);
  }

  private getURL(): string {
    return this.document.location.href;
  }

  private getPageName(): string {
    const href =
      this.document.location.pathname.indexOf('destinations/') > -1
        ? this.getUrlParam(this.document.location.pathname)
        : '';
    const preferceCntrPageName =
      this.document.location.pathname.indexOf('preference-center/') > -1
        ? this.processPreferenceCntrPageName()
        : '';
    return href
      ? `${this.currentPageName} ${href}`
      : preferceCntrPageName
      ? preferceCntrPageName
      : this.currentPageName;
  }
  private getUrlParam(href): string {
    const dest: string[] = [];
    href
      .split('destinations/')[1]
      .split('-')
      .map(destination => {
        dest.push(destination.charAt(0).toUpperCase() + destination.slice(1));
      });
    return `| ${dest.join(' ')}`;
  }
  private previousPageNameInfo(): string {
    const prevPage = this.cookieStorage.getAnalyticsPrevPageCookie();
    return prevPage ? prevPage : this.previousPageName;
  }

  private setDefaultValues(values?: AnalyticsValues): AnalyticsValues {
    const channel =
      this.currentPageName === 'JetBlue | SignIn Page'
        ? 'NTS_TrueBlue_Dotcom'
        : 'NTS_TRADITIONAL WEB';

    return {
      url: this.getURL(),
      tbStatus: `${this.mosaicStatus} | ${this.cardStatus}`,
      tbNumber: this.tbNumber,
      tbPoints: this.tbPoints,
      prop60: this.getURL(),
      pageName: this.getPageName(),
      prop61: this.previousPageNameInfo(),
      eVar2: this.languageService.getLanguage3Letter(),
      channel,
      ...values,
    };
  }
}
