import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BodyTextContainerComponent } from '@dynamic-components/body-text-container';
import { UIModule } from '@shared/ui';

import { TbBottomSectionModule } from '../shared/tb-bottom-section/tb-bottom-section.module';
import { HeaderSignedInDialogComponent } from './header-signed-in-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    UIModule,
    BodyTextContainerComponent,
    TbBottomSectionModule,
  ],
  declarations: [HeaderSignedInDialogComponent],
  exports: [HeaderSignedInDialogComponent],
})
export class HeaderSignedInDialogModule {}
