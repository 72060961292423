<div
  class="mobile-header menu-shadow w-100 ph3 flex justify-between items-center bg-core-blue"
  [ngClass]="{ 'menu-open': (isModalOpen$ | async) }"
  id="jb-header-theme"
>
  <div class="left flex items-center">
    <button
      [ngClass]="{ open: (isNavMenuOpen$ | async) }"
      (click)="toggleNavMenu()"
      class="nav-icon z-2"
      aria-label="toggle navigation menu"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
    <jb-logo [logoData]="jetblueLogo" class="ml1"></jb-logo>
  </div>

  <div class="right flex items-center logged-in-wrapper" *ngIf="!hideTb">
    <ng-container *ngIf="isLoggedIn$ | async; else notLoggedIn">
      <jb-tb-logged-in
        [content]="trueBlueSection.loggedInSection"
      ></jb-tb-logged-in>
    </ng-container>

    <ng-template #notLoggedIn>
      <jb-header-mobile-sign-in
        [trueBlueSection]="trueBlueSection"
      ></jb-header-mobile-sign-in>
    </ng-template>

    <!-- vertical divider -->
    <span class="mh2"></span>

    <jb-header-mobile-shopping-cart
      *ngIf="shoppingCart?.emptyCart"
      [shoppingCart]="shoppingCart"
    >
    </jb-header-mobile-shopping-cart>

    <!-- search section area -->
    <ng-container *ngIf="searchNavSection">
      <a
        class="white"
        role="button"
        href="javascript:void(0);"
        [jbSitemapLinker]="
          searchNavSection.link[0] && searchNavSection.link[0].href
        "
        [target]="searchNavSection.link[0].newWindow"
      >
        <jb-icon [name]="searchNavSection.image[0].src"> </jb-icon>
        <hr class="db absolute bottom-0 hr-underline w-0" />
      </a>
    </ng-container>
  </div>
</div>

<div
  *ngIf="isNavMenuOpen$ | async"
  class="mobile-nav-menu"
  [ngClass]="{
    slideDown: (isNavMenuOpen$ | async)
  }"
>
  <jb-subnav-mobile [subNavSections]="subNavSections"></jb-subnav-mobile>
</div>
