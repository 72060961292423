import { Inject, Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { AnalyticsService } from '@core/analytics';
import { JBABTestService } from '@core/analytics/abtest';
import { CMSService } from '@core/cms';
import { parseHeader } from '@core/cms/api-transform/cms-header.api-transform';
import { searchSiteMapFromPath } from '@core/cms/cms.utils';
import { CMS_FOOTER_FALLBACK_PARSED } from '@core/cms/cms-footer-fallback.const';
import { CMS_HEADER_FALLBACK_PARSED } from '@core/cms/cms-header-fallback.const';
import { CMS_HOMEPAGE_FALLBACK } from '@core/cms/cms-homepage-fallback.const';
import { PersonalizationService } from '@core/cms/personalization/personalization.service';
import { HeaderResponse } from '@core/cms/types/cms-header.response.type';
import { HttpService } from '@core/http';
import {
  DOCUMENT,
  InjectionTokenIsWebComponent,
  IS_WEB_COMPONENT,
} from '@core/injection-tokens';
import { RouterService } from '@core/router';
import { SeoService } from '@core/seo';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';
import { OriginsFacade } from '@store/origins/origins.facade';
import { RouterEffects } from '@store/router/router.effects';
import { WINDOW } from 'jb-component-library';
import { combineLatest, merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  share,
  startWith,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import {
  ChooseGlobalComponentBackgroundUrl,
  FetchGlobalComponentBackgroundUrl,
  GlobalActions,
  RequestHeader,
  RequestTemplate,
  ResolveFallbackTemplate,
  SetGlobalComponentConfig,
} from './global.actions';
import { GlobalFacade } from './global.facade';
import { GlobalEffectsFooter } from './partials/global.effects.footer';

@Injectable()
export class GlobalEffects {
  constructor(
    private actions$: Actions,
    private globalFacade: GlobalFacade,
    private cmsService: CMSService,
    private router: RouterService,
    private abTestService: JBABTestService,
    private seoService: SeoService,
    @Inject(IS_WEB_COMPONENT)
    private isWebComponent: InjectionTokenIsWebComponent,
    private analyticsService: AnalyticsService,
    @Inject(DOCUMENT) private document,
    @Inject(WINDOW) private window,
    private originsFacade: OriginsFacade,
    private globalEffectsFooter: GlobalEffectsFooter,
    private personalizationService: PersonalizationService,
    private routerEffects: RouterEffects,
  ) {}

  onInit$ = this.actions$.pipe(ofType(ROOT_EFFECTS_INIT), share());

  // runs only if header has not been requested. see requestHeaderFooter$
  requestHeader$ = this.actions$.pipe(
    ofType<RequestHeader>(GlobalActions.REQUEST_HEADER),
    switchMap(
      personalizationData =>
        this.cmsService.getHeader(
          personalizationData?.payload,
        ) as Observable<HeaderResponse>,
    ),
    share(),
  );

  requestTemplate$ = this.actions$.pipe(
    ofType<RequestTemplate>(GlobalActions.REQUEST_TEMPLATE),
    switchMap(({ payload }) => {
      if (payload.origin) {
        return of(payload).pipe(
          withLatestFrom(this.originsFacade.airportsMap),
          map(([p, airportMap]) => {
            return {
              ...p,
              origin: {
                ...(p.origin || {}),
                code: p.origin.code || '',
              },
            };
          }),
        );
      } else {
        return of(payload);
      }
    }),
    switchMap(payload => {
      return this.cmsService.getTemplateData(payload);
    }),
    share(),
  );

  resolveCmsFailure$ = this.actions$.pipe(
    ofType<ResolveFallbackTemplate>(GlobalActions.RESOLVE_FALLBACK_TEMPLATE),
    share(),
  );

  requestCssOverrides$ = createEffect(
    () =>
      merge(
        this.requestTemplate$.pipe(map(payload => payload.endpoint)),
        this.requestHeader$.pipe(map(() => '/application/header')),
      ).pipe(
        map(routePath => {
          // query for style element and pass along for use later in filter and tap(),
          // to avoid expensive document query again
          const existingStyleElem: HTMLStyleElement =
            this.document.getElementById('per-page-override-css');
          return [routePath, existingStyleElem];
        }),
        filter(([_, styleEle]) => {
          // filter out requests for css override if global overrides are already present
          return !(styleEle && booleanStringCheck(styleEle.dataset.global));
        }),
        switchMap(([routePath, styleEle]) =>
          // get css text from cms and pass along style element ref and the route path
          this.cmsService
            .getCssOverrides(routePath)
            .pipe(map(payload => [payload, styleEle])),
        ),
        tap(([payload, existingStyleElem]) => {
          // unpack the response
          const [css, applyCssOverride, routePath] = payload;

          // remove existing css override element if it exists
          if (existingStyleElem) {
            existingStyleElem.remove();
          }
          const newStyleElem: HTMLStyleElement =
            this.document.createElement('style');
          // assign the id so that we can find and delete it later
          newStyleElem.setAttribute('id', 'per-page-override-css');

          if (routePath === '/application/header' && applyCssOverride) {
            // assign global data flag to true if the route path is the header
            // and the applyCssOverride flag is true
            // need to check if header is explicitly set to true, cause undefined or false returns the
            // default css override of EXP A. If we didnt check explicitly for header, the global flag would
            // always be set and prevent other overrides from ever being set
            newStyleElem.dataset.global = 'true';
          }

          newStyleElem.appendChild(this.document.createTextNode(css));
          const headElem = this.document.querySelector('head');
          headElem.appendChild(newStyleElem);
        }),
      ),
    { dispatch: false },
  );

  trackABTestOnLoad$ = createEffect(
    () =>
      this.onInit$.pipe(
        switchMap(() => {
          return this.router.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
          );
        }),
        tap(() => this.abTestService.trackABTestInFullStory()),
      ),
    { dispatch: false },
  );

  requestHeaderSuccess$ = createEffect(() =>
    this.requestHeader$.pipe(
      filter(HttpService.isSuccessful),
      map(result => this.globalFacade.loadHeader(parseHeader(result))),
    ),
  );

  // this saves the header content without formatting it first
  // todo: we should generally do this for most of our data, when we format it first, we end up destroying information
  //  can be relevant later, and our formatting can happen where it makes more sense - in the selector
  requestHeaderSuccessSaveRaw$ = createEffect(() =>
    this.requestHeader$.pipe(
      filter(HttpService.isSuccessful),
      map(result => this.globalFacade.loadHeaderRaw(result)),
    ),
  );

  /** Request the header and footer data on navigation completion. Stop requesting after
   * header and footer have been resolved each once.
   */
  requestHeaderFooter$ = createEffect(() => {
    // memoize the login status
    let prevIsLoggedIn: any = false;
    return combineLatest([
      // this onInit$ runs after requestHeaderSuccess$ & requestFooterSuccess$ are init with false
      this.onInit$,
      this.router.isNavigatingToTemplate.pipe(
        // this filter stops the header / footer request
        // navData is an object created in router.service
        // during bootstrap url is undefined after router is init url is valid

        filter(navData => {
          if (this.isWebComponent === 'header') {
            return true;
          }
          return navData.url !== '' && !navData.navigating;
        }), // true when we end navigation
      ),
      this.requestHeaderSuccess$.pipe(
        map(() => true),
        startWith(false),
        distinctUntilChanged(),
      ),
      this.globalEffectsFooter.requestFooterSuccess$.pipe(
        map(() => true),
        startWith(false),
        distinctUntilChanged(),
      ),
      // after user is authenticated this is updated to true
      this.routerEffects.waitForLeanProfile$.pipe(debounceTime(150)),
      // this ALWAYS runs during bootstrap even for web components
      this.personalizationService.getGlobalPersonalizationData(), // returns global personalizations from home page in sitemap
    ]).pipe(
      // debounce to give header/footer both chance to finalize, otherwise header/footer that was requested first will
      // finalize and make another request for request that was made second and is still loading
      debounceTime(500),
      takeUntil(this.resolveCmsFailure$), // do not req header/footer when cms has failed
      /* this check is not required since we validate header, footer and login status in mergeMap block below
      takeWhile(([_, __, header, footer]) => !header || !footer), // only make requests while the header or footer havent been loaded
      */
      switchMap(
        ([
          _,
          navData,
          requestHeaderSuccess,
          requestFooterSuccess,
          isLoggedin,
          { routeData }, // from home page in sitemap
        ]) => {
          return this.personalizationService
            .getPersonalizationData(routeData, isLoggedin)
            .pipe(
              map(personalizationData => [
                navData,
                requestHeaderSuccess,
                requestFooterSuccess,
                {
                  ...routeData,
                  ...personalizationData,
                  loggedIn: isLoggedin,
                },
              ]),
            );
        },
      ),
      mergeMap(data => {
        // this block should only run in dotcom not in outside apps i.e. manage-trips, true blue
        // see getHeaderForWebComponentOnInit$
        const [
          navData,
          requestHeaderSuccess,
          requestFooterSuccess,
          personalizationData,
        ] = data;
        const actions: any[] = [];

        const sitemapObject = searchSiteMapFromPath(
          this.window.__SITEMAP.sitemap,
          navData.url.split('?')[0],
        );

        // as per PR comments reorg conditions
        const showHeader = !sitemapObject
          ? true
          : !booleanStringCheck(sitemapObject?.hideHeader);
        const showFooter = !sitemapObject
          ? true
          : !booleanStringCheck(sitemapObject?.hideFooter);

        const loginChanged = personalizationData.loggedIn !== prevIsLoggedIn;

        if (showHeader && (!requestHeaderSuccess || loginChanged)) {
          actions.push(this.globalFacade.requestHeader(personalizationData));
        }
        if (showFooter && (!requestFooterSuccess || loginChanged)) {
          actions.push(this.globalFacade.requestFooter(personalizationData));
        }
        prevIsLoggedIn = !!personalizationData.loggedIn;
        // else scenario is the both are not requested
        return actions;
      }),
    );
  });

  requestTemplateSuccess$ = createEffect(() =>
    this.requestTemplate$.pipe(
      filter(HttpService.isSuccessful),
      tap(() => {
        this.abTestService.getOmnitureABTestExperienceValue(() => {
          this.analyticsService.trackEvent('AB Test Expereience');
        });
      }),
      // saves dynamic components metadata in store, so defaultRendererProcess$
      // can read it in basic template.
      // to-do link basic template to requestTemplateSuccess$
      map(response => this.globalFacade.loadTemplate(response)),
      tap(state =>
        this.seoService.updatePageDescription(
          state?.payload?.data?.description,
        ),
      ),
      tap(state => this.seoService.updateRobotsNoIndex(state?.payload?.data)),
      tap(state => this.seoService.addOpenGraphMetaData(state?.payload?.data)),
    ),
  );

  resolveCmsFailureEffect = createEffect(() =>
    this.resolveCmsFailure$.pipe(
      map(() => this.globalFacade.loadTemplate(CMS_HOMEPAGE_FALLBACK)),
      tap(() => this.globalFacade.loadFooter(CMS_FOOTER_FALLBACK_PARSED)),
      tap(() => this.globalFacade.loadHeader(CMS_HEADER_FALLBACK_PARSED)),
      tap(state =>
        this.seoService.updatePageDescription(
          state?.payload?.data?.description,
        ),
      ),
      tap(state => this.seoService.updateRobotsNoIndex(state?.payload?.data)),
      tap(state => this.seoService.addOpenGraphMetaData(state?.payload?.data)),
    ),
  );

  requestTemplateFailure$ = createEffect(
    () =>
      this.requestTemplate$.pipe(
        filter(HttpService.hasError),
        tap(err => {
          /* eslint-disable no-console */
          console.warn('TEMPLATE NOT FOUND, REDIRECTING TO NOT-FOUND.', err);
        }),
        map(() => this.router.navigate('not-found')),
      ),
    { dispatch: false },
  );

  fetchGlobalComponentBackgroundUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchGlobalComponentBackgroundUrl>(
        GlobalActions.FETCH_GLOBAL_COMPONENT_BACKGROUND_URL,
      ),
      takeUntil(
        this.globalFacade.globalComponent.pipe(
          filter(globalComponentState => !!globalComponentState.bgUrl),
        ),
      ),
      switchMap(() => this.cmsService.getHomePagePatternBlockCMS()),
      map(patterns => new ChooseGlobalComponentBackgroundUrl(patterns)),
    ),
  );

  chooseGlobalComponentBackgroundUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChooseGlobalComponentBackgroundUrl>(
        GlobalActions.CHOOSE_GLOBAL_COMPONENT_BACKGROUND_URL,
      ),
      takeUntil(
        this.globalFacade.globalComponent.pipe(
          filter(globalComponentState => !!globalComponentState.bgUrl),
        ),
      ),
      map(({ payload: patterns }) => {
        // Picks random image index from the images array
        const randomizedIndex = this.cmsService.updateBackgroundIndex(patterns);
        if (!!patterns && patterns.length) {
          const bgUrl = !!patterns[randomizedIndex]
            ? patterns[randomizedIndex].src
            : patterns[0].src;
          return new SetGlobalComponentConfig({ bgUrl });
        }
      }),
    ),
  );
}
