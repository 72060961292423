/* eslint-disable no-cond-assign */
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@core/injection-tokens';
import { interval } from 'rxjs';
import { filter, map, take, takeWhile, tap } from 'rxjs/operators';

import {
  CookieStorageService,
  FrontEndLoggerService,
  WINDOW,
} from '../../core';
import { AppConfigService } from '../app-config/app-config.service';
const privacyManagerLoop = true;

@Injectable({
  providedIn: 'root',
})
export class DynamicYieldService {
  private renderer: Renderer2;
  private cookieReadAttempts = 0;
  private privacyManagerApiMaxChecks = 30;
  private privacyManagerApiNumberOfChecks = 0;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window,
    private envConfig: AppConfigService,
    private rendererFactory: RendererFactory2,
    private cookies: CookieStorageService,
    private feLogger: FrontEndLoggerService,
  ) {
    this.init();
    // Trustarc Consent Listener
  }
  init() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.window.DY = this.window.DY || {};
    const sectionId = this.envConfig?.dynamicYield?.sectionId;
    if (sectionId) {
      const dynamicAPITypes = ['dynamic', 'static'];
      dynamicAPITypes.forEach(apiType => {
        const srcUrl = `https://cdn.dynamicyield.com/api/${sectionId}/api_${apiType}.js`;
        const dynamicYieldScript = this.renderer.createElement('script');
        dynamicYieldScript.type = 'text/javascript';
        this.renderer.setAttribute(dynamicYieldScript, 'src', srcUrl);
        this.renderer.appendChild(this.document.head, dynamicYieldScript);
      });

      this.listenForPrivacyManagerApi();
      this.setDyidCookie();
    } else {
      this.feLogger.logError({
        message: 'DynamicYieldService initialized without sectionId defined',
      });
    }
  }
  listenForPrivacyManagerApi() {
    /*
First step is to register with the CM API to receive callbacks when a preference update occurs. You must wait for the CM API (PrivacyManagerAPI object) to exist on the page before registering.
*/
    const __i__ =
      self.postMessage &&
      interval(1000)
        .pipe(
          takeWhile(
            () =>
              this.privacyManagerApiNumberOfChecks <
              this.privacyManagerApiMaxChecks,
          ),
          tap(() => this.privacyManagerApiNumberOfChecks++),
          map(() => {
            if (this.window.PrivacyManagerAPI) {
              const apiObject = {
                PrivacyManagerAPI: {
                  action: 'getConsentDecision',
                  timestamp: new Date().getTime(),
                  self: self.location.host,
                },
              };
              self.top.postMessage(JSON.stringify(apiObject), '*');
              return true;
            }
          }),
          filter(apiExists => !!apiExists),
          take(1),
        )
        .subscribe({
          next: () => {
            this.window.addEventListener(
              'message',
              this.checkConsent.bind(this),
            );
          },
          complete: () => {},
        });
  }

  setDyidCookie() {
    if (this.cookieReadAttempts < 20) {
      this.cookieReadAttempts++;
      const cookieValue = this.cookies.getCookie('_dyid');
      if (cookieValue) {
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        this.cookies.setCookie('_dyid_server', cookieValue, expires);
      } else {
        setTimeout(() => this.setDyidCookie(), 100);
      }
    }
  }

  checkConsent(e: any, d: any) {
    const __dispatched__ = {}; // Map of previously dispatched preference levels
    const cookieKey = 'notice_gdpr_prefs';

    /*
     Callbacks will occur in the form of a PostMessage event. This code listens for the appropriately formatted PostMessage event,
     gets the new consent decision, and then pushes the events into the GTM framework. Once the event is submitted, that consent decision
     is marked in the 'dispatched' map so it does not occur more than once.
     */
    try {
      if (e.data) {
        d = JSON.parse(e.data);
        if (
          d.PrivacyManagerAPI?.capabilities &&
          d.PrivacyManagerAPI.action === 'getConsentDecision'
        ) {
          const newDecision = this.window.PrivacyManagerAPI.callApi(
            'getGDPRConsentDecision',
            this.window.location.host,
          ).consentDecision;
          if (newDecision) {
            const cookieValue = this.cookies.getCookie(cookieKey);
            const isUserConsented = cookieValue !== '0:';
            this.updateDynamicYieldConsent(isUserConsented);

            newDecision.forEach(function (label) {
              if (!__dispatched__[label]) {
                this.window.dataLayer &&
                  this.window.dataLayer.push({
                    event: 'GDPR Pref Allows ' + label,
                  });
                __dispatched__[label] = 1;
              }
            });
          }
        }
      }
    } catch (xx) {
      /** not a cm api message **/
    }
  }
  setPageContext(currentUrl: string, isHomePage: boolean) {
    const recommendationContext = isHomePage
      ? { type: 'HOMEPAGE' }
      : { type: 'OTHER', data: [{ url: currentUrl }] }; // We can make this data section anything we want
    this.window.DY.recommendationContext = recommendationContext;
  }

  updateDynamicYieldConsent(value: boolean) {
    this.feLogger.logData({ message: 'DY consent updated', newValue: value });
    this.window.DY.userActiveConsent = {
      accepted: value,
    };
    this.window.DYO.ActiveConsent.updateConsentAcceptedStatus(value);
    try {
      localStorage.setItem('userActiveConsent', JSON.stringify(value));
    } catch (e) {
      console.error("We couldn't save the value of the consent,", value);
    }
  }
  handleLogin(tbNumber: string) {
    this.window.DY.API('event', {
      name: 'Login',
      properties: {
        dyType: 'login-v1',
        cuid: tbNumber,
        cuidType: 'TrueBlueNumber',
      },
    });
  }
}
