import { B6_TYPE } from '@core/app-config/types/mocks';
import { CookieStorageService } from '@core/storage';
import { IS_USER_MOSAIC } from '@core/storage/cookie-storage.keys';
import { booleanStringCheck } from '@shared/ui/utils/global-utils/boolean-string-check';

import { IS_USER_BIRTHDAY_CMS_KEY } from '../types';
import { Numeric } from '../types/personalization.type';
import { PERSONALIZATION_CONFIG } from './personalization.config';

export function formatPersonalization(personalizations): string {
  let result = '';
  if (!!personalizations && !!personalizations.length) {
    const entries = personalizations.split('&&');

    if (entries && entries.length > 0) {
      const filteredPersonalizations = entries
        .map(item => {
          const personalization = item.replace('==', '=');

          return personalization.includes('targetedOrigin')
            ? personalization
            : filterPersonalization(personalization);
        })
        .filter(Boolean);

      if (filteredPersonalizations.length > 0) {
        result = `personalizations=${encodeURIComponent(
          JSON.stringify(
            personalizationUrlUnescape(filteredPersonalizations.join('&')),
          ),
        )}`;
      }
    }
  }

  return result;
}

function personalizationUrlUnescape(personalizations: string) {
  return personalizations.replace(/==/g, '=').replace(/&&/g, '&');
}

/*
  19 Dec 2019 :
  This might look excessive but we need to preserve airports' shape in
  order to retain existing function calls' results, none of which had
  unit tests.

  None of the airports belonging to a geolocation/city seem to be used in
  practice, though ; we might want to investigate if/how we could/should lift
  airports' schema.
 */
export function filterPersonalization(
  personalizationString: string,
): string | null {
  const [personalizationKey, personalizationValue] =
    personalizationString.split('=');

  const eligibleParameterValues = PERSONALIZATION_CONFIG[personalizationKey];

  // This personalization isn't in the config can have a large range of values
  if (personalizationKey === 'countryCode') {
    return personalizationString;
  }

  // If the key is not found in PERSONALIZATION_CONFIG, return null
  if (eligibleParameterValues === undefined) {
    return null;
  }

  // Check if the eligibleParameterValues is 'numeric'
  if (
    isNumericType(eligibleParameterValues) &&
    isNumeric(personalizationValue)
  ) {
    return personalizationString;
  }

  if (Array.isArray(eligibleParameterValues)) {
    // Check for string values in the config array
    if (eligibleParameterValues.some(value => typeof value === 'string')) {
      const foundKeyValue = eligibleParameterValues.includes(
        personalizationValue as any,
      );
      if (foundKeyValue) {
        return personalizationString;
      }
    }

    // Try an airport parameter
    if (
      eligibleParameterValues.some(value => typeof value === 'object') &&
      eligibleParameterValues[0]?.hasOwnProperty(personalizationValue)
    ) {
      return personalizationString;
    }
  }

  // If no conditions are met, return the personalizationKey with '=null'
  return `${personalizationKey}=null`;
}

function isNumericType(value: any): value is Numeric {
  return value === 'numeric';
}

function isNumeric(value: string): boolean {
  return !isNaN(Number(value));
}

export function getPersonalization(personalizationName: string): any {
  const values = PERSONALIZATION_CONFIG[personalizationName];
  return values
    ? {
        name: personalizationName,
        values,
      }
    : undefined;
}

export function getTargetedOrigin(origin): string {
  return `targetedOrigin==${origin || 'null'}`;
}

export function getUpcomingTrip(hasUpcomingTrip: boolean): string {
  return `hasUpcomingTrip==${hasUpcomingTrip || 'null'}`;
}

/**
 * Converts the numeric value of TrueBlue points balance to a string corresponding to the range in which the value falls
 * @param tbPointsBalance TrueBlue points balance numeric value
 * @returns a string of the range in which the value falls
 */

export function convertTBPointBalanceFromNumericToRange(tbPointsBalance) {
  switch (true) {
    case tbPointsBalance >= 1 && tbPointsBalance <= 499:
      return 'RANGE_1';
    case tbPointsBalance >= 500 && tbPointsBalance <= 999:
      return 'RANGE_2';
    case tbPointsBalance >= 1000 && tbPointsBalance <= 2499:
      return 'RANGE_3';
    case tbPointsBalance >= 2500 && tbPointsBalance <= 5000:
      return 'RANGE_4';
    case tbPointsBalance >= 5001 && tbPointsBalance <= 7500:
      return 'RANGE_5';
    case tbPointsBalance >= 7501 && tbPointsBalance <= 10000:
      return 'RANGE_6';
    case tbPointsBalance >= 10001:
      return 'RANGE_7';
    default:
      return 'RANGE_0';
  }
}

export function getPointsBalance(points: number): string {
  return `trueBluePointsBalance==${
    convertTBPointBalanceFromNumericToRange(points) || 'null'
  }`;
}

export function getTravelCreditHistory(travelCreditHistory: boolean): string {
  return `travelCreditHistory==${travelCreditHistory || 'null'}`;
}

export function getMosaicStatus(mosaicStatus: string): string {
  const formattedMosaicStatus = (mosaicStatus || '')
    .toLowerCase()
    .replace(/\s+/g, '');
  return `mosaicStatus==${formattedMosaicStatus || 'null'}`;
}

/**
 * Converts the numeric value of Travel Bank balanceto a string corresponding to the range in which the value falls
 * @param travelBankBalance Travel bank balance numeric value
 * @returns a string of the range in which the value falls
 */

export function convertTravelBankBalanceFromNumericToRange(travelBankBalance) {
  switch (true) {
    case travelBankBalance === 0:
      return 'TIER_0';
    case travelBankBalance >= 0.01 && travelBankBalance <= 20:
      return 'TIER_1';
    case travelBankBalance >= 20.01 && travelBankBalance <= 40:
      return 'TIER_2';
    case travelBankBalance >= 40.01 && travelBankBalance <= 60:
      return 'TIER_3';
    case travelBankBalance >= 60.01 && travelBankBalance <= 80:
      return 'TIER_4';
    case travelBankBalance >= 80.01 && travelBankBalance <= 100:
      return 'TIER_5';
    case travelBankBalance >= 101.01:
      return 'TIER_6';
    default:
      return 'TIER_0';
  }
}

export function getTravelBankBalance(balance: number): string {
  return `travelBankBalance==${
    convertTravelBankBalanceFromNumericToRange(balance) || 'null'
  }`;
}

export function getCountryCode(cookie?): string {
  return `countryCode==${cookie || 'null'}`;
}

export function getCardType(cookie?): string {
  return `cardType==${cookie || 'null'}`;
}

export function getRecentSearches(recentSearch: boolean): string {
  return `recentSearch==${recentSearch || 'null'}`;
}

export function getRecentSearchDestination(searchDestination: string): string {
  return `searchDestination==${searchDestination || 'null'}`;
}

export function getIsFirstVisit(firstVisit: boolean): string {
  return `firstVisit==${firstVisit || 'null'}`;
}

export function getIsLastVisitSixMonths(lastVisitSixMonths: boolean): string {
  return `lastVisitSixMonths==${lastVisitSixMonths || 'null'}`;
}

export function getIsBirthday(birthdayCookie) {
  return birthdayCookie === 'true'
    ? `${IS_USER_BIRTHDAY_CMS_KEY}==true`
    : `${IS_USER_BIRTHDAY_CMS_KEY}==null`;
}

export function getVisitorType(
  cookieStorageService: CookieStorageService,
  loggedIn: boolean,
) {
  // Check if the 'b6type' cookie exists and its value is either 'JBA' or 'WEB'.
  // If true, the user is identified as a CP/Jetblue customer.

  const b6Type = cookieStorageService.getCookie(B6_TYPE);

  if (b6Type === 'JBA' || b6Type === 'WEB') {
    return `visitor==JetBlue`;
  }

  if (!loggedIn) {
    return `visitor==guest`;
  }

  const isMosaicUser = booleanStringCheck(
    cookieStorageService.getCookie(IS_USER_MOSAIC),
  );

  return `visitor==${isMosaicUser ? 'mosaic' : 'standard'}`;
}

export function getLanguage(language?): string {
  return `currentLang==${language || 'null'}`;
}

export const __TEST__ = {
  getPersonalization,
};
