import { AccessToken } from '@okta/okta-auth-js';
import jwtDecode from 'jwt-decode';
/**
 * Function that checks if access token belongs to CP users;
 * If it is not, the function should convert it into a boolean value.
 * @param { string} accessToken the access token
 * @returns {boolean} true or false
 */
export function isJwtForCpUser(accessToken: string | AccessToken): boolean {
  let isCpUser = false;

  try {
    if (typeof accessToken === 'string') {
      const decodedToken = jwtDecode(accessToken);
      isCpUser = decodedToken?.[`profileType`] === 'JBA';
    } else {
      isCpUser = accessToken?.claims?.profileType === 'JBA';
    }
  } catch (_) {
    return false;
  }

  return isCpUser;
}
