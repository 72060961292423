import { AccessToken } from '@okta/okta-auth-js';
import { allPass, compose, has, hasPath, not, omit, pathOr } from 'ramda';

import {
  ProfileActions,
  ProfileActionsUnion,
} from '../profile/profile.actions';
import { AuthActions, AuthActionsUnion } from './auth.actions';
import { asOktaToken, AuthState } from './types';

export const initialAuthState: AuthState = {
  authProfileForOktaAndLeanProfileService: null,
  oktaSessionCheckStatus: 'Idle',
  accessToken: null,
  oktaError: null,
  idToken: null,
  uid: null,
  isHeaderWebComponent: false,
  isFetchingProfile: null,
  authProfileError: null,
};

export function authReducer(
  state = initialAuthState,
  action: AuthActionsUnion | ProfileActionsUnion,
): AuthState {
  switch (action.type) {
    case AuthActions.OKTA_SET_TOKEN_SUCCESS:
      const isMalformed = compose(
        not,
        allPass([has('idToken'), hasPath(['accessToken', 'accessToken'])]),
        pathOr({}, ['oktaResponse', 'tokens']),
      )(action);

      if (isMalformed) {
        return state;
      }
      const accessToken: AccessToken = action.oktaResponse.tokens.accessToken;
      return {
        ...state,
        idToken: action.oktaResponse.tokens.idToken,
        accessToken: {
          value: accessToken.accessToken,
          accessToken: asOktaToken(accessToken.accessToken),
          requestedAt: new Date().getTime(),
          expiresAt: accessToken.expiresAt,
          claims: accessToken.claims,
          tokenType: accessToken.tokenType,
          userinfoUrl: accessToken.userinfoUrl,
          authorizeUrl: accessToken.authorizeUrl,
          scopes: accessToken.scopes,
        },
      };
    case AuthActions.OKTA_RENEW_TOKEN_SUCCESS:
      return {
        ...state,
        [action.key]: action.token,
      };
    case AuthActions.OKTA_UID_VALUE:
      return {
        ...state,
        uid: action.payload,
      };
    case AuthActions.OKTA_USER_LOGGED_OUT_SUCCESS:
      return {
        ...state,
        ...initialAuthState,
      };
    case AuthActions.OKTA_USER_LOGGED_OUT_FAILURE:
      return {
        ...state,
        ...initialAuthState,
      };
    case AuthActions.OKTA_SET_TOKEN_FAILURE:
    case AuthActions.OKTA_RENEW_TOKEN_FAILURE:
      return {
        ...state,
        ...initialAuthState,
        ...{
          oktaError: action.err,
        },
      };

    case ProfileActions.PROFILE_LOADED:
      if (
        action['error'] &&
        !!action.payload &&
        action.payload['code'] === 'SESSION_EXPIRED'
      ) {
        return initialAuthState;
      }
      return state;

    case AuthActions.LEAN_PROFILE_SUCCEEDED:
      return {
        ...state,
        accessToken: {
          ...state.accessToken,
          // Our Personalization Service requires the accessToken to work.
          // Make sure that the accessToken is stored after LeanProfile is fetched.
          accessToken: action.payload.requestToken as any,
        },
        authProfileForOktaAndLeanProfileService: {
          ...state.authProfileForOktaAndLeanProfileService,
          leanProfileFragment: action.payload.authProfile,
        },
      };

    case AuthActions.LEAN_PROFILE_REQUESTED:
      return {
        ...state,
        authProfileError: null,
      };

    case AuthActions.LEAN_PROFILE_FAILED:
      return {
        ...state,
        authProfileError: action.payload,
        authProfileForOktaAndLeanProfileService: omit(
          ['leanProfileFragment'],
          state.authProfileForOktaAndLeanProfileService,
        ),
      };

    case AuthActions.LEAN_PROFILE_PENDING:
      return {
        ...state,
        isFetchingProfile: action.payload,
      };

    case AuthActions.OKTA_PRE_EXISTING_SESSION_STATUS_UPDATE:
      return {
        ...state,
        oktaSessionCheckStatus: action.payload,
      };

    case AuthActions.LOGOUT_USER_FOR_HEADER:
      return {
        ...state,
        authProfileForOktaAndLeanProfileService: null,
      };

    default:
      return state;
  }
}
