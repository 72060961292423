import { CookieStorageService } from '@core/storage';
import { getProfileAge } from '@store/auth/auth.utils';
import { AuthProfile } from '@store/auth/types';
import { RWBProfile } from '@store/profile/types/profile-details-response.interface';

import { booleanStringCheck } from './boolean-string-check';

/**
 * Function that register Logs into Full Story
 * @param {string} eventName The name of the event that will be registered on FS.
 * @param {{}} eventProperties Properties set on FS for each Event.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function registerLogFullStory(
  eventName: string,
  eventProperties = {},
  fsWindow: Window,
): void {
  if (isSSR()) {
    return;
  }

  if (fsWindow['FS']) {
    fsWindow['FS'].event(eventName, eventProperties);
  }
}

/**
 * FullStory is an asynchronous script and for some cases, we must wait it to load before trying to register a log.
 * Use this function when you need to register a log BEFORE any user interaction.
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function waitForFullStory(fsWindow: Window) {
  if (isSSR()) {
    return Promise.resolve(null);
  }
  return new Promise<void>((resolve, reject) => {
    let attempts = 0;
    const fsInterval = setInterval(() => {
      if (fsWindow['FS']) {
        clearInterval(fsInterval);
        resolve();
      } else {
        attempts++;
      }
      if (attempts > 50) {
        clearInterval(fsInterval);
        reject();
      }
    }, 250);
  });
}

/**
 * Function that register user identity
 * @param {RWBProfile | LeanProfile} profile Auth Profile Data.
 * @param {number} travelBankBalance User travel balance.
 */
export function fullstoryIdentify(
  profile: RWBProfile | AuthProfile,
  travelBankBalance?: number,
) {
  if (!profile || isSSR() || !window['FS']) {
    return;
  }
  let properties: any;
  let uid: string;
  try {
    // Lean Profile Data
    if (profile?.['membershipid']) {
      const authProfile = profile as AuthProfile;
      uid = authProfile.membershipid;
      const trueBlueStatus =
        authProfile.authProfileSource === 'leancreditprofile'
          ? 'None'
          : booleanStringCheck(authProfile.isMosaic)
          ? authProfile.mosaicTier
          : 'TrueBlue';

      properties = {
        displayName: `${authProfile.fname} ${authProfile.lname}`,
        TrueBlueStatus: trueBlueStatus,
        age: authProfile.age,
        age_int: authProfile.age,
        ...(travelBankBalance && {
          travelBankBalance,
        }),
        ...(authProfile.cardStatus && { cardType: authProfile.cardStatus[0] }),
        ...(authProfile.points && {
          pointsBalance: Number(authProfile.points),
          pointsBalance_int: Number(authProfile.points),
        }),
      };
    } else {
      // Rwb Profile Data
      const rwbProfile = profile as RWBProfile;
      uid = rwbProfile?.loyalty?.[0]?.membershipId || rwbProfile?.id;
      const statusMap: { [key: string]: string } = {
        MOSAIC1: 'Mosaic 1',
        MOSAIC2: 'Mosaic 2',
        MOSAIC3: 'Mosaic 3',
        MOSAIC4: 'Mosaic 4',
      };

      const TrueBlueStatus = statusMap[rwbProfile?.customerLevel] || 'TrueBlue'; // Default to 'TrueBlue' if not found
      const travelBankBalance = rwbProfile?.pay?.reduce((sum, pay) => {
        if (pay.type === 'TB') {
          return sum + Number(pay.balance);
        }
        return sum;
      }, 0);

      properties = {
        displayName: `${rwbProfile?.fname} ${rwbProfile?.lname}`,
        TrueBlueStatus,
        age: getProfileAge(rwbProfile?.dateOfBirth),
        age_int: getProfileAge(rwbProfile?.dateOfBirth),
        pointsBalance: Number(rwbProfile?.loyalty?.[0]?.amount),
        pointsBalance_int: Number(rwbProfile?.loyalty?.[0]?.amount),
        ...(travelBankBalance && { travelBankBalance }),
        ...(rwbProfile?.cardStatus &&
          rwbProfile?.cardStatus?.[0] && {
            cardType: rwbProfile?.cardStatus?.[0],
          }),
      };
    }

    window['FS'].identify(uid, properties);
  } catch (e) {
    console.error(e);
  }
}

/**
 * Function that Anonymize authenticated users from Full Story
 * @param {Window} fsWindow Injectable Window to capture and register FS logs
 */
export function anonymizeUserFullStory(
  fsWindow: Window,
  cookieStorageService: CookieStorageService,
): void {
  if (isSSR()) {
    return;
  }
  cookieStorageService.removeCookie('jbFSIdentify');
  if (fsWindow['FS']) {
    fsWindow['FS'].anonymize();
  }
}

function isSSR(): boolean {
  return typeof window === 'undefined' ? true : false;
}
