import {
  PreferenceCenterActions,
  PreferenceCenterActionsUnion,
} from './preference-center.actions';

export const initialPreferenceCenterState = {
  personalInfo: null,
  profilePicture: null,
  travelPreferences: null,
  futureReservationStatus: null,
  paymentInfo: null,
  paymentInfoApiError: null,
};

export function preferenceCenterReducer(
  state = initialPreferenceCenterState,
  action: PreferenceCenterActionsUnion,
) {
  switch (action.type) {
    case PreferenceCenterActions.STORE_MEMBER_DATA:
      return {
        ...state,
        personalInfo: action.payload,
      };
    case PreferenceCenterActions.STORE_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.payload,
      };
    case PreferenceCenterActions.STORE_MEMBER_PREFERENCES:
      return {
        ...state,
        travelPreferences: action.payload,
      };
    case PreferenceCenterActions.STORE_FUTURE_RESERVATION_STATUSE:
      return {
        ...state,
        futureReservationStatus: action.payload,
      };

    case PreferenceCenterActions.UPDATE_TRAVEL_CREDITS:
      return {
        ...state,
        paymentInfo: {
          ...state?.paymentInfo,
          Response: {
            ...state?.paymentInfo?.Response,
            hasTravelCreditHistory: true,
            TravelBankBalance: {
              ...state?.paymentInfo?.Reponse?.TravelBankBalance,
              BalanceRS: {
                ...state?.paymentInfo?.Reponse?.TravelBankBalance?.BalanceRS,
                AccountBalance: action.payload,
              },
            },
          },
        },
      };
    case PreferenceCenterActions.STORE_MEMBER_PAYMENT:
      return {
        ...state,
        // If the response includes a TravelBankBalance object, it indicates that the user either has or has had a travel bank balance.
        // If the object is undefined, it means the user has never had a credit history.
        paymentInfo: action.payload?.Response?.TravelBankBalance
          ? {
              ...action.payload,
              Response: {
                ...action.payload?.Response,
                hasTravelCreditHistory: true,
              },
            }
          : {
              Response: {
                ...action.payload?.Response,
                hasTravelCreditHistory: false,
                TravelBankBalance: { BalanceRS: { AccountBalance: 0 } },
              },
            },
      };
    case PreferenceCenterActions.GET_MEMBER_PAYMENT:
      return {
        ...state,
        paymentInfo: false,
      };
    default:
      return state;
  }
}
