<a
  #firstFocusLink
  href="javascript:void(0)"
  jbFocusOn
  moveFocusTo="first-focus"
  skipToMain="true"
  class="f7 b ml5 pa2 royal-blue bg-white skip-to-content"
  [attr.aria-hidden]="isModalOpen$ | async"
  [style.visibility]="(isModalOpen$ | async) ? 'hidden' : 'visible'"
  >Skip to main content</a
>
<jb-install-prompt></jb-install-prompt>

<ng-container *ngIf="_showMenu && headerResponse">
  <!-- super global nav -->
  <jb-super-global-nav
    class="relative dot-header-alert-z10 db bg-off-white"
    *ngIf="
      headerResponse.globalNav &&
      headerResponse.globalNav.isEnabled === 'true' &&
      !(hideSuperGlobalNav$ | async)
    "
    [data]="headerResponse.globalNav"
    id="jb-header-theme"
    [attr.aria-hidden]="isModalOpen$ | async"
    [style.visibility]="(isModalOpen$ | async) ? 'hidden' : 'visible'"
  ></jb-super-global-nav>
  <!-- travel alert desktop -->
  <div
    *ngIf="!(hideTravelAlert$ | async)"
    class="dot-header-alert-z10 bg-core-blue w-100 relative"
    #headerTravelAlerts
    [attr.aria-hidden]="isModalOpen$ | async"
    [style.display]="(isModalOpen$ | async) ? 'none' : 'block'"
  >
    <jb-travel-alerts></jb-travel-alerts>
  </div>
  <!-- desktop header block start -->
  <jb-header-desktop
    #headerDesktop
    [headerData]="headerResponse"
    class="sticky-header-desktop dot-header-z9 dn db-ns bg-core-blue w-100 relative"
    id="jb-header-theme"
    [hideTb]="hideTb"
    *ngIf="!(isMobile$ | async)"
  >
  </jb-header-desktop>
  <!-- [details]="headerResponse.countdownClock" -->
  <dot-countdown
    *ngIf="
      !(hideCountdownClock$ | async) &&
      headerResponse &&
      headerResponse.countdownClock &&
      !(isMobile$ | async)
    "
    [details]="headerResponse.countdownClock"
    class="dn db-ns"
  >
  </dot-countdown>
  <!-- desktop header block end -->

  <!-- mobile header block start -->
  <jb-header-mobile
    #headerMobile
    [headerData]="headerResponse"
    [hideTb]="hideTb"
    class="sticky-header-mobile dot-header-z9 db relative w-100 dn-ns bg-core-blue"
    id="jb-header-theme"
    *ngIf="isMobile$ | async"
  >
  </jb-header-mobile>
  <dot-countdown
    *ngIf="
      !(hideCountdownClock$ | async) &&
      headerResponse &&
      headerResponse.countdownClock &&
      (isMobile$ | async)
    "
    [details]="headerResponse.countdownClock"
    class="db dn-ns"
    [attr.aria-hidden]="isModalOpen$ | async"
    [style.visibility]="(isModalOpen$ | async) ? 'hidden' : 'visible'"
  >
  </dot-countdown>
  <!-- mobile header block end -->
</ng-container>

<!--
  WebComponent Header:
  The header may take some time to load due to fetching the "headerNew" with Personalizations.
  To prevent displaying an empty container, we are going to inject a skeleton loader while the header data is being fetched.
-->
<jb-header-skeleton
  *ngIf="!headerResponse && isWebComponent === 'header'"
  [hideSuperGlobalNav]="true"
></jb-header-skeleton>
