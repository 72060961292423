import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountdownModule } from '@shared/countdown/countdown.module';
import { LinkIconModule } from '@shared/link-icon/link-icon.module';
import { SkeletonModule } from '@skeleton/skeleton.module';
import { JbAvatarModule } from 'jb-component-library';

import { JBLogoModule } from '../jb-logo';
import { TrueblueModule } from '../trueblue/trueblue.module';
import { UIModule } from '../ui';
import { TravelAlertsModule } from './../travel-alerts/';
import { HeaderComponent } from './header.component';
import { HeaderStyleService } from './header.component.service';
import { HeaderService } from './header.service';
import { HEADER_DESKTOP_DECLARATIONS } from './header-desktop';
import { HEADER_MOBILE_DECLARATIONS } from './header-mobile';
import { HeaderSignedInDialogModule } from './header-signed-in-dialog/header-signed-in-dialog.module';
import { INSTALL_PROMPT_DECLARATIONS } from './install-prompt';
import { NavigationModule } from './navigation';
import { TbSignedOutModule } from './tb-signed-out/tb-signed-out.module';

const MODULES = [
  CommonModule,
  NavigationModule,
  TrueblueModule,
  UIModule,
  JBLogoModule,
  JbAvatarModule,
  TravelAlertsModule,
  SkeletonModule,
  CountdownModule,
  LinkIconModule,
  TbSignedOutModule,
  HeaderSignedInDialogModule,
];

const DECLARATIONS = [
  ...HEADER_DESKTOP_DECLARATIONS,
  ...HEADER_MOBILE_DECLARATIONS,
  ...INSTALL_PROMPT_DECLARATIONS,
  HeaderComponent,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...DECLARATIONS],
  exports: [...MODULES, ...DECLARATIONS],
  providers: [HeaderService, HeaderStyleService],
})
export class HeaderModule {}
