import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID,
  RendererFactory2,
  ViewEncapsulation,
} from '@angular/core';
import { EventsService } from '@core/events';
import { LanguageService } from '@core/language/language.service';
import { SessionStorageService } from '@core/storage';
import { AuthFacade } from '@store/auth/auth.facade';
import { AuthProfile, OktaJWT } from '@store/auth/types';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AppConfigService } from '../app-config';
import { DOCUMENT, WINDOW } from '../injection-tokens';
@Injectable({ providedIn: 'root' })
export class WidgetService implements OnDestroy {
  private oktaToken: OktaJWT;
  private customerId: string;
  private subscriptions: Subscription = new Subscription();
  private hideChat = false;
  isASAPPScriptAttached = false;
  private localeAtRender = 'en-US';

  constructor(
    @Inject(WINDOW) private window,
    @Inject(DOCUMENT) private document,
    private appConfigService: AppConfigService,
    private rendererFactory: RendererFactory2,
    private authFacade: AuthFacade,
    private eventsService: EventsService,
    private sessionStorage: SessionStorageService,
    private languageService: LanguageService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (this.appConfigService.authenticationEnabled) {
      this.subscriptions.add(
        combineLatest([
          this.authFacade.authProfile.pipe(filter<AuthProfile>(Boolean)),
          this.authFacade.oktaToken.pipe(filter<OktaJWT>(Boolean)),
        ]).subscribe(([authProfile, token]) => {
          this.oktaToken = token;
          this.customerId = authProfile.membershipid;

          if (this.window['ASAPP']) {
            // Authenticate when user opens ASAPP chat
            this.window['ASAPP']('on', 'chat:show', () => {
              this.window['ASAPP']('getState', state => {
                if (!state.hasCustomerId) {
                  this.setCutomerASAPP(authProfile.membershipid, token);
                }
              });
            });
          }
        }),
      );

      this.hideChat = this.sessionStorage.getItem<any>('asapp-chat-sdk-storage')
        ?.isLoggingIn;
    }
  }

  setCutomerASAPP(customerId: string, oktaToken: string) {
    const ASAPPAuthProps = {
      CustomerId: customerId,
      ContextProvider: callback => {
        const context = {
          Auth: {
            Token: oktaToken,
          },
        };
        callback(context);
      },
      UserLoginHandler: data => {
        this.eventsService.dispatchCustomEvent(
          EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
        );
      },
    };
    this.window['ASAPP']('setCustomer', ASAPPAuthProps);
  }

  openCookieConsent() {
    if (this.window['truste']?.eu) {
      this.window['truste'].eu.clickListener();
    }
  }

  attachASAPPWidgetScript(): void {
    if (this.isASAPPScriptAttached) {
      return;
    }
    const asappScript = `(function (w, d, h, n, s) {
      s = d.createElement('script');
      (w[n] =
        w[n] ||
        function () {
          (w[n]._ = w[n]._ || []).push(arguments);
        }),
        (w[n].Host = h),
        (s.async = 1),
        (s.src = h + '/chat-sdk.js'),
        (s.type = 'text/javascript'),
        d.body.appendChild(s);
    })(window, document, 'https://sdk.asapp.com', 'ASAPP')`;
    this.appendScript(asappScript, 'text', false);
    this.isASAPPScriptAttached = true;
  }

  // attach Sherpa API widget
  appendSherpaWidget() {
    const attr = this.appConfigService.delayThirdPartyJS ? 'delay' : 'src';
    this.appendScript(
      `https://sdk.joinsherpa.io/widget.js?appId=${this.appConfigService.widgets.sherpaConfig.appId}&affiliateId=${this.appConfigService.widgets.sherpaConfig.affiliateId}`,
      attr,
      false,
    );
  }

  renderASAPPWidget() {
    if (!this.appConfigService.loadASAPPWidget) {
      return;
    }

    this.attachASAPPWidgetScript();
    const ASAPPLoadProps = {
      APIHostname: `${this.appConfigService.widgets.asappConfig.hostname}`,
      AppId: `${this.appConfigService.widgets.asappConfig.appId}`,
      Language: this.languageService.getLocale(),
      Display: {
        Align: 'right',
        AlwaysShowMinimize: true,
        BadgeColor: '#0033a0',
        BadgeType: 'badge',
        PrimaryColor: '#0033a0',
        DarkColor: '#121212',
      },
      onLoadComplete: () => {
        const normalizedUrl = this.handleLanguageInUrl();

        const shouldAutoOpenASAPP =
          this.appConfigService.ASAPPOpenOnLoadURLs?.some(
            autoOpenPathname => autoOpenPathname === normalizedUrl,
          );

        if (shouldAutoOpenASAPP) {
          this.openASAPP();
        } else {
          // if shouldAutoOpenASAPP is false, then make sure widget isOpen is false
          this.sessionStorage.setItem('asapp-chat-sdk-storage', {
            isOpen: false,
          });
        }
      },
    };

    this.localeAtRender = this.languageService.getLocale();

    let ASAPPAuthProps = {};
    if (this.appConfigService.authenticationEnabled) {
      ASAPPAuthProps = {
        CustomerId: this.customerId,
        ContextProvider: callback => {
          const context = {
            Auth: {
              Token: this.oktaToken,
            },
          };
          callback(context);
        },
        UserLoginHandler: data => {
          this.eventsService.dispatchCustomEvent(
            EventsService.CUSTOM_EVENTS.JB_HEADER_LOGIN_CLICKED_OUTPUT_EVENT,
          );
        },
      };
    }

    if (this.hideChat) {
      return;
    }

    this.window['ASAPP']('load', {
      ...ASAPPLoadProps,
      ...ASAPPAuthProps,
    });
  }

  handleASAPPWidgetVisibility(url: string): void {
    if (isPlatformServer(this.platformId)) {
      // server side code, req for ssr
      return;
    }
    if (!this.appConfigService.loadASAPPWidget) {
      return;
    }
    if (
      !this.window['ASAPP'] ||
      this.localeAtRender !== this.languageService.getLocale()
    ) {
      this.renderASAPPWidget();
    } else {
      this.window.ASAPP('refresh');
    }

    if (this.window['ASAPP']) {
      this.window['ASAPP']('getState', ({ isActiveSession }) => {
        const normalizedUrl = this.handleLanguageInUrl();

        const isWhitelistedPage =
          this.appConfigService.ASAPWhiteListedURLs.some(
            whiteListedUrl => normalizedUrl.startsWith(whiteListedUrl), // might use regex to do exact match minus params
          );
        const displayChat =
          isActiveSession || isWhitelistedPage ? 'flex' : 'none';

        document.documentElement.style.setProperty(
          '--jb-show-asapp-chat',
          displayChat,
        );
      });
    }
  }

  appendJtpSdk(cb: () => void) {
    const attr = this.appConfigService.delayThirdPartyJS ? 'delay' : 'src';
    const script = this.appendScript(
      this.appConfigService.widgets.jtpSdk.src,
      attr,
      false,
      {
        async: 'true',
        defer: 'true',
      },
    );
    script.addEventListener('load', cb, false);
  }

  appendiOSSystemBarColourScript() {
    const attr = this.appConfigService.delayThirdPartyJS ? 'delay' : 'src';
    this.appendScript(
      '/ui-assets/third-party-utils/system-bar-theme.js',
      attr,
      false,
    );
  }

  appendThirdPartyScripts() {
    // Third party scripts loaded in partytown service worker
    const styleScriptSrcs = [
      '/ui-assets/third-party-utils/grid-check.js',
      '/ui-assets/third-party-utils/flyingFocus.js',
    ];
    styleScriptSrcs.forEach(scriptSrc =>
      this.appendScript(scriptSrc, 'src', true),
    );

    this.appendScript(
      this.appConfigService.jtpsdkTrackingScriptsUrl,
      'src',
      false,
    );

    // Uses DOM so we have to delay instead of partytown
    this.appendiOSSystemBarColourScript();
  }

  appendHTML(el: string, attrs: { [key: string]: string }): void {
    const renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-2',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
    const elem = renderer.createElement(el);
    if (!!attrs && Object.keys(attrs).length) {
      Object.keys(attrs).forEach(key =>
        renderer.setAttribute(elem, key, attrs[key]),
      );
    }
    renderer.appendChild(this.document.body, elem);
  }

  appendScript(
    scriptSrc: string,
    attr: string = 'text',
    loadInWebWorker: boolean,
    additionalAttr?: { [key: string]: string },
  ): HTMLElement {
    const type =
      loadInWebWorker && this.appConfigService.partytown
        ? 'text/partytown'
        : 'text/javascript';
    const renderer = this.rendererFactory.createRenderer(this.document, {
      id: '-1',
      encapsulation: ViewEncapsulation.None,
      styles: [],
      data: {},
    });
    const script = renderer.createElement('script');
    renderer.setAttribute(script, 'type', type);
    attr === 'delay'
      ? renderer.setAttribute(script, 'delay', scriptSrc)
      : (script[attr] = scriptSrc);
    if (!!additionalAttr && Object.keys(additionalAttr).length) {
      Object.keys(additionalAttr).forEach(key =>
        renderer.setAttribute(script, key, additionalAttr[key]),
      );
    }
    renderer.appendChild(this.document.body, script);
    return script;
  }

  openASAPP(): void {
    if (this.window.ASAPP) {
      this.window.ASAPP('show');
    }
  }

  unhideChat() {
    this.sessionStorage.setItem('hideASAPPChat', false);
    this.hideChat = false;
    this.sessionStorage.setItem('asapp-chat-sdk-storage', {
      isOpen: false,
    });
    this.renderASAPPWidget();
  }

  resetASAPPChatSessionStorage() {
    this.hideChat = false;
    this.sessionStorage.setItem('hideASAPPChat', false);
    this.sessionStorage.removeItem('asapp-chat-sdk-storage');
    this.sessionStorage.removeItem('asappChatSDK-iframe-status');
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleLanguageInUrl(): string {
    const pathname = this.window.location.pathname;
    const languagePrefixes = this.appConfigService.i18nSupportedLanguages;
    const regex = new RegExp(`^/(${languagePrefixes.join('|')})/`);

    // Remove the language prefix
    const normalizedUrl = pathname.replace(regex, '');

    // Ensure the normalized URL starts with a '/'
    return normalizedUrl.startsWith('/') ? normalizedUrl : `/${normalizedUrl}`;
  }
}
