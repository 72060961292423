<ng-container *ngIf="showBanner">
  <ng-container>
    <div class="countdown-container bg-white">
      <a
        id="sale-clickable-countdown-mini"
        class="tc bg-core-blue no-underline white flex justify-center items-center sale-countdown-mini"
        [jbSitemapLinker]="saleDetails?.link?.href"
        [target]="saleDetails?.link?.newWindow"
      >
        <div
          class="b f5 f4-l pl2 pv1 pv2-l mr1 mr3-ns flex flex-column items-start-l items-baseline"
        >
          <span class="tl">{{ saleDetails?.heading }}</span>
          <div class="dn-l normal dib f7">
            <div class="flex inline-flex items-baseline">
              <span class="underline-hover mr1">{{
                saleDetails?.ctaTitle
              }}</span>
              <jb-icon class="fill-royal-blue" name="rightFilled"></jb-icon>
            </div>
          </div>
        </div>

        <div
          *ngIf="showClock"
          class="pv3 dn dib-l pl2 pr2"
          [ngClass]="{ 'o-0': !this.started }"
        >
          {{ saleDetails?.subheader }}
        </div>

        <ng-container
          *ngIf="showClock"
          [ngTemplateOutlet]="theNumbers"
        ></ng-container>

        <div class="ml2 b dn dib-l pt1">
          <div class="flex inline-flex items-baseline">
            <span class="underline-hover mr1">{{ saleDetails?.ctaTitle }}</span>
            <jb-icon class="fill-royal-blue" name="rightFilled"></jb-icon>
          </div>
        </div>
      </a>
    </div>
  </ng-container>

  <!-- Just the numbers -->
  <ng-template #theNumbers>
    <div
      class="no-underline flex flex-row justify-center items-center"
      [ngClass]="{
        'o-0': !this.started
      }"
    >
      <ng-container
        [ngTemplateOutlet]="timeBox"
        [ngTemplateOutletContext]="{
          number: timer.totalHours,
          copy: unitsOfTime.hours
        }"
      ></ng-container>
      <div class="tc white flex b f2">
        <span class="flex">:</span>
      </div>
      <ng-container
        [ngTemplateOutlet]="timeBox"
        [ngTemplateOutletContext]="{
          number: timer.totalMinutes,
          copy: unitsOfTime.minutes
        }"
      ></ng-container>
      <div class="tc white flex b f2">
        <span class="flex">:</span>
      </div>
      <ng-container
        [ngTemplateOutlet]="timeBox"
        [ngTemplateOutletContext]="{
          number: timer.totalSeconds,
          copy: unitsOfTime.seconds
        }"
      ></ng-container>
    </div>
  </ng-template>

  <ng-template #timeBox let-number="number" let-copy="copy">
    <div class="tc bg-white core-blue br3 ma1 b ph2">
      <div class="pv1 f3">
        {{ number }}
      </div>
      <div class="legal normal nt2">{{ copy }}</div>
    </div>
  </ng-template>
</ng-container>
