export const MOCK_FLIGHT_TRACKER_COPY = {
  heading: 'Flight Tracker',
  flightNoErrorMsg: 'No valid flight number found.',
  inputFlightNumber: 'Enter Flight Number',
  inputFlightNumberTitle: 'Input a flight number to search',
  flightNoInputLabel: 'Flight Number',
  checkStatusBtn: 'Check status',
  questionsLabel: 'Questions?',
  questionsDropdown1: 'Call 1-800-JETBLUE (538-2583)',
  questionsDropdown2: 'Hearing or speech impaired: TTY/TDD 711',
  mobileAlertLabel: 'Get flight status alerts with the JetBlue mobile app',
  mobileStickyFlight: 'Flight',
  mobileStickyDepart: 'Departs',
  mobileStickyArrive: 'Arrives',
  tabletStickyFlightNumber: 'Flight Number',
  tabletStickyOrigin: 'Origin',
  tabletStickyScheduledDeparture: 'Scheduled Departure',
  tabletStickyScheduledArrival: 'Scheduled Arrival',
  tabletStickyDestination: 'Destination',
  tabletStickyStatus: 'Status',
  tabletStickyNewDeparture: 'New Departure',
  tabletStickyNewArrival: 'New Arrival',
  directFlights: 'Direct flights',
  connectingFlights: 'Connecting flights',
  refreshStatus: 'Refresh status',
  terminal: 'Terminal:',
  now: 'Now',
  gate: 'Gate:',
  viewFlightMap: 'View Flight Map',
  byRouteTab: 'By route',
  byFlightNoTab: 'By flight number',
  yesterday: 'Yesterday',
  today: 'Today',
  tomorrow: 'Tomorrow',
  dropdownLabel: 'Departure Date',
  serverError: 'We are experiencing technical difficulties.',
  subServerError:
    'Please try again or call 1-800-JETBLUE (538-2583) for assistance.',
  subServerErrorPrefix: 'Please try again or',
  flightDelayedMsg: `Delayed flights leave as soon as possible. Please be at the airport 60-90
   minutes prior to "Scheduled Departure".`,
  flightCancelledMsg: `We apologize, this flight has been cancelled. Please call us at
   1-800-538-2583 for assistance.`,
  flightCancelledMsgPrefix: `We apologize, this flight has been cancelled. Please `,
  flightError: 'No Flights Found',
  subFlightError: 'Please check that you have the correct flight and time.',
  dialogDeparture: 'Departs',
  dialogArrival: 'Arrives',
  dialogRemainingFlightTime: 'Remaining Flight Time',
  dialogPlaceholderRemainingFlightTime: '1 hour, 16 min',
  lastUpdated: 'Last updated: ',
  dialogMobileTitle: 'Flight Info',
  dialogLargeTitle: 'to',
  newOriginMsg: 'Please take note that the departure airport has changed from',
  newOriginPeriod: '.',
  hidingPartnerAirlinesMsg: 'Flights for partner airlines are not listed here.',
  contactUs: ' contact us',
  forAssistance: ' for assistance.',
  inboundFlight: {
    arrival: 'arrival',
    arrives: 'Arrives',
    destinationAirport: 'Destination airport',
    errors: {
      internalError:
        'Something went wrong retrieving this information, please try again later.',
      noDataError: 'No inbound flight data available',
    },
    hideButtonText: 'Hide inbound plane',
    loading: {
      inProgress: 'Loading inbound flight data in progress',
      complete: 'Inbound flight data loaded successfully',
      failed: 'Inbound flight data failed to load',
    },
    originAirport: 'Origin airport',
    showButtonText: 'Show inbound plane',
    status: 'Status',
  },
};
